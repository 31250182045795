import React, { useState, useRef } from "react";
import InputMask from "react-input-mask";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Slider from "@material-ui/core/Slider";
import InputWithStartIcon from "pub-components/InputWithStartIcon";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";
import MaskedInputField from "pub-components/ui-controls/ui-components/MaskInputField";
import UilClockThree from "@iconscout/react-unicons/icons/uil-clock-three";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import { secondsToTime, timeToSeconds } from '../generic.methods';

const DurationFilter = ({ filterValue, onChange }) => {
  const [minTime, setMinTime] = useState(
    filterValue?.value ? secondsToTime(filterValue?.value?.min) : '00:00:00'
  );
  const [maxTime, setMaxTime] = useState(
    filterValue?.value ? secondsToTime(filterValue?.value?.max) : '00:00:00'
  );
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [minTimeError, setMinTimeError] = useState('');
  const [maxTimeError, setMaxTimeError] = useState('');

  const inputRef = useRef(null);



  const handleMinChange = e => {
    let value = e.target.value;
    if (!validateTime(value)) {
      setMinTimeError('Invalid time format');
      return;
    }
    if (timeToSeconds(value) > timeToSeconds(maxTime)) {
      setMinTimeError('Min time cannot be greater than max time');
      return;
    }
    setMinTimeError('');
    setMinTime(value);
  };

  const validateTime = time => {
    const [hh, mm, ss] = time.split(':').map(Number);
    return hh <= 59 && mm <= 59 && ss <= 59;
  };

  const handleMaxChange = e => {
    let value = e.target.value;
    if (!validateTime(value)) {
      setMaxTimeError('Invalid time format');
      return;
    }
    if (timeToSeconds(value) < timeToSeconds(minTime)) {
      setMaxTimeError('Max time cannot be less than min time');
      return;
    }
    setMaxTimeError('');
    setMaxTime(value);
  };

  const handleSliderChange = (event, newValue) => {
    setMinTime(secondsToTime(newValue[0]));
    setMaxTime(secondsToTime(newValue[1]));
  };

  const applyFilter = () => {
    if (validateTime(minTime) && validateTime(maxTime)) {
      onChange({
        ...filterValue,
        value: { min: timeToSeconds(minTime), max: timeToSeconds(maxTime) }
      });
      setShowPopover(false);
    }
  };

  const clearInput = () => {
    setMinTime('00:00:00');
    setMaxTime('00:00:00');
    onChange({ ...filterValue, value: null });
    setShowPopover(false);
  };

  const handleInputClick = event => {

    setAnchorEl(inputRef.current);

    setShowPopover(prev => !prev);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const minSeconds = timeToSeconds(minTime);
  const maxSeconds = timeToSeconds(maxTime);
  const valueAvailable = !!minSeconds || !!maxSeconds;

  return (
    <div className="date-range-container">
      <InputWithStartIcon

        ref={inputRef}

        startTextOrIcon={null}
        endTextOrIcon={
          <>
            {valueAvailable ? (
              <UilTimes onClick={clearInput} />
            ) : (
              <span onClick={handleInputClick} className="d-inline-block">
                <UilAngleDown />
              </span>
            )}
          </>
        }
        name="duration-range"
        label="Duration Range"
        placeholder="Select"
        handleOnChange={() => {}}
        value={valueAvailable ? `${minTime} - ${maxTime}` : ''}
        readOnly={true}
        onClick={handleInputClick}
      />

      <Popper
        id={id}
        style={{ width: '300px' }}
        open={showPopover}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={() => setShowPopover(false)}>
          <Paper className="p-3">
            <div className="d-flex mb-2" style={{ gap: '10px' }}>
              <MaskedInputField
                label="Minimum"
                mask="99:99:99"
                maskChar="0"
                value={minTime}
                onChange={handleMinChange}
                placeholder="HH:MM:SS"
                endIcon={UilClockThree}
                onIconClick={() => console.log('Time picker clicked!')}
              />
              <MaskedInputField
                label="Maximum"
                mask="99:99:99"
                maskChar="0"
                value={maxTime}
                onChange={handleMaxChange}
                placeholder="HH:MM:SS"
                endIcon={UilClockThree}
                onIconClick={() => console.log('Time picker clicked!')}
              />
            </div>
            <Slider
              value={[minSeconds, maxSeconds]}
              onChange={handleSliderChange}
              valueLabelDisplay="off"
              valueLabelFormat={value => secondsToTime(value)}
              min={0}
              max={3600}
              step={5}
            />
            <button
              className="btn btn-primary btn-sm mt-2 w-100"
              onClick={applyFilter}
            >
              Apply
            </button>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default DurationFilter;